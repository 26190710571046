@media screen and (width:768px){
    .section_header_wrapper {
        width: 100%;
        height: 8%;
        /* background-color: #848498; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 9px;
    }
    .section_header_wrapper .button_wrapper button {
        display: flex;
        font-size: 9px;
    }
    .section_header_wrapper .paginationWrapper{
        
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 9px;
    }

    .paginationInputWrapper {
        display: flex;
        align-items: center;
        position: relative;
        width: 40%;
        height: 21px;
        border: 1px solid var(--Primary_color);
        border-radius: 3px;
    }
    .paginationInputWrapper .find_btn {
        border: unset;
        background-color: var(--Primary_color);
        padding: 0px 6px;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        color: #fff;
    }
    .paginationInputWrapper h6{
        font-size: 9px;
    }
    .logout_box_wrapper .profile_box_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: var(--Primary_color);
        gap: 0.938rem;
        padding-left: 0.2rem;
        position: relative;
        transition: 0.3s ease;
    }
    .survey_analysis_tab_wrapper {
        display: flex;
        align-items: normal;
        /* font-size: 12px; */
    }
}
@media screen and (min-width:768px) and (max-width:1023px) {
    .side_nav_section {
        width: 23%;
    }

    .routes_view_section {
        width: 77%;
    }

    .tab {
        padding: 0.375rem 0.188rem 0.375rem 0.425rem;
    }

    .tab .icon {
        margin-right: 0.5rem;
    }

    .tab span,
    .dropdown_tab span {
        font-size: 14px;
    }

    .tab .icon .svg-inline--fa {
        font-size: 0.9rem;
    }

    .dropdown_tab {
        padding: 0.375rem 0.188rem 0.375rem 2rem;
    }

    .dashboard_graph_wrapper canvas {
        height: 350px !important;
    }

    .dashboard_card_wrapper .card_wrapper {
        height: 110px;
        padding: 5px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .dashboard_card_wrapper .card_wrapper .inner_card .svg-inline--fa {
        font-size: 15px;
    }

    .dashboard_card_wrapper .card_wrapper .inner_card h6 {
        font-size: 9px;
    }

    .dashboard_card_wrapper .card_wrapper .count h6 {
        font-size: 9px;
    }

    .current_progress_wrap .msi_panel_wrapper {
        width: 90%;
        margin: auto;
    }

    .organization_table {
        min-width: 1400px;
    }

    .table_header h5,
    .table_data h6 {
        font-size: 12px;
    }

    .data_analysis_card_wrapper .survey_card {
        min-width: 40%;
    }

    .survey_analysis_graph_wrapper canvas {
        max-height: 220px;
    }

    .counter_card_wrapper .counter_card {
        width: calc(100% / 5);
    }

    .counter_card_wrapper {
        gap: 8px;
    }

    .counter_card_wrapper .counter_card h6,
    .counter_card_wrapper .counter_card h5 {
        font-size: 12px;
    }

    .msi_panel_wrapper {
        width: 92%;
        margin: auto;
        overflow-y: auto;
    }

    .msi_panel_wrapper .msi_panel_card_wrapper {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .download_template_wrapper .card_wrapper {
        min-width: 240px;
    }

    .modal_wrapper .card_wrapper,
    .form_wrapper_main .card_wrapper {
        width: 95%;
    }

    .designation_wrapper .designation_card {
        max-height: 80%;
    }

    .designation_wrapper .check_box_wrapper label {
        font-size: 12px;
        margin-bottom: 0;
    }

    .booth_access_wrapper .add_access_button {
        right: -9%;
    }

    .modal_wrapper .card_body,
    .form_wrapper_main .card_body {
        width: 100%;
        overflow: auto;
    }
}


/* s */
@media screen and (min-width:1440px) {
    .top_header .title {
        font-size: 1rem;
    }

    .table_header h5 {
        font-size: 16px;
    }

    .table_data h6 {
        font-size: 14px;
    }
}

@media screen and (width:2560px) {
    .top_header .title {
        font-size: 1.563rem;
    }

    .table_header h5 {
        font-size: 19px;
    }

    .table_data h6 {
        font-size: 17px;
    }

    .tab .title {
        font-size: 35px;
    }

    .tab .icon {
        margin-right: 1.5rem;
        font-size: 35px;
    }

    .modal_wrapper .card_header .title h5 {
        font-size: 25px;
    }

    .modal_wrapper .card_wrapper, .form_wrapper_main .card_wrapper {
        min-height: 50%
    }

    .modal_wrapper .card_header .close_button, .form_wrapper_main .card_header .close_button {
        width: 30px;
        height: 30px;
    }

    .input_box_wrapper label {
        font-size: 18px;
    }

    .input_box_wrapper input, .input_box_wrapper select, .input_box_wrapper textarea, .react-datepicker-wrapper input {
        font-size: 18px;
    }

    .modal_wrapper .submit_button_wrapper .filter_button, .form_wrapper_main .submit_button_wrapper .filter_button {
        margin-top: 30px;
    }

    .action .action_button {
        font-size: 16px;
        padding: 1px 11px;
    }

    .action_designation .svg-inline--fa {
        font-size: 20px;
    }

}

@media screen and (min-device-width: 1024px) and (max-device-width:2560px) {

    .voting_area_graph_wrapper .svg_wrapper:nth-child(2) {
        top: -10%;
        left: 28%;
        width: 130px;
        height: 110px;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(3) {
        top: 62.3%;
        right: 16.5%;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(4) {
        top: 73%;
        left: -13%;
        width: 150px;
        height: 130px;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(7) {
        top: 145.5%;
        left: -6.4%;
        width: 129px;
        height: 130px;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(9) {
        top: 110%;
        left: 37%;
        width: 104px;
        height: 100px;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(10) {
        top: 94%;
        right: 10%;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(11) {
        top: 230%;
        left: 5%;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(12) {
        top: 180%;
        right: 23%;
        width: 156px;
        height: 95px;
    }

    .voting_area_graph_wrapper .svg_wrapper:nth-child(13) {
        top: 181%;
        right: 13%;
        width: 80px;
        height: 60px;

    }

}