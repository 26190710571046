@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --font_family: 'Lato', sans-serif;
    --primary_font_size: 1rem;
    --secondary_font_size: 0.938rem;
    --ternary_font_size: 0.875rem;
    --Primary_color: #38CAB3;
    --secondary_color: #4F4F6D;
    --ternary_color: #848498;
    --box_shadow: rgba(170, 170, 170, 0.2) 0px 3px 6px 0px;
    --text_shadow: 3px 0px 6px #818181;
    --border_radius: 0.313rem;
    --input_margin: 1.563rem;
    --border: 0.063rem solid #D9D9D9;
}

a {
    text-decoration: none;
}

body {
    font-family: var(--font_family);
}

h1,
h4,
h5,
h6,
ul {
    margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* Scroll Bar css */
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

::-webkit-scrollbar:vertical {
    width: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--secondary_color);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--Primary_color);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0fc5aa;
}


.form-switch .form-check-input {
    min-width: 3rem;
    height: 20px;
    border: 1px solid var(--Primary_color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='lightgreen'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    background-color: var(--Primary_color);
}

.form-switch .form-check-input:focus {
    box-shadow: none;
}

.form_switch_wrapper {
    position: relative;
}

.form_switch_wrapper span {
    position: absolute;
    top: 51%;
    transform: translate(-50%, -50%);
    font-size: 6px;
    font-weight: bold;
}

/*=====css for validation message=========*/
.srv-validation-message {
    color: #e23a3a;
    font-size: var(--ternary_font_size);
    margin-top: 3px;
}

.swal2-confirm {
    background-color: var(--Primary_color) !important;
    border: unset !important;
}

.swal2-confirm:focus {
    box-shadow: none !important;
}

.data_not_available_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--ternary_color);
}

.dashboard_data_not_available {
    text-align: center;
    margin-top: 10rem;
    color: var(--ternary_color);
}

.refresh_icon {
    color: var(--Primary_color);
    cursor: pointer;
}

/* ======css for dashboard layout========== */
.dashboard_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.side_nav_section {
    width: 20%;
    height: 100%;
    background-color: #fff;
    box-shadow: var(--box_shadow);
    overflow-x: hidden;
}

.routes_view_section {
    width: 80%;
    height: 100%;
}

.side_nav_wrapper {
    width: 100%;
    height: 100%;
}

.logo {
    height: 15%;
    display: grid;
    place-items: center;
}

.logo h1 {
    color: var(--ternary_color);
    font-weight: bold;
}

.nav_list_wrapper {
    height: 79%;
    overflow-y: auto;
}

.nav_list_wrapper::-webkit-scrollbar {
    width: 0;
}

.logout_box_wrapper {
    height: 6%;
}

.logout_box_wrapper .profile_box_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--Primary_color);
    gap: 0.938rem;
    padding-left: 0.625rem;
    position: relative;
    transition: 0.3s ease;
}

.logout_box_wrapper .box_main {
    display: grid;
    place-items: center;
    transition: 0.2s ease-in-out;
}

.logout_box_wrapper .profile_box {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    border: 0.063rem solid var(--Primary_color);
    display: grid;
    place-items: center;
    color: var(--Primary_color);
    cursor: pointer;
}

.logout_box_wrapper .profile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.profile .profile_title {
    color: #fff;
    font-size: 0.813rem;
}

.logout_box_wrapper .profile_icon {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    background-color: #fff;
    display: grid;
    place-items: center;
}

.profile_icon .svg-inline--fa {
    color: var(--Primary_color);
    font-size: 0.625rem;
}

.profile_box_wrapper .close_button {
    position: absolute;
    top: 50%;
    left: 95%;
    transform: translate(-50%, -50%);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: #fff;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.profile_box_wrapper .close_button .svg-inline--fa {
    font-size: 0.75rem;
    color: var(--Primary_color);
}

.tab {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.188rem 0.375rem 1.125rem;
    position: relative;
    cursor: pointer;
    margin-bottom: 0.625rem;
    color: var(--ternary_color);
    font-weight: bold;
}

.tab span,
.dropdown_tab span {
    font-size: var(--primary_font_size);
}

.tab .icon {
    margin-right: 1rem;
}

.tab .icon .svg-inline--fa {
    font-size: 1.125rem;
}

.dropdown_tab {
    padding: 0.375rem 0.188rem 0.375rem 3.325rem;
    cursor: pointer;
    margin-bottom: 0.625rem;
    color: var(--ternary_color);
    font-weight: bold;
    position: relative;
}

.dropdown_tab .sub_dropdown_tab {
    margin-left: 15px;
}

.dropdown_tab ul li {
    list-style-type: none;
    color: var(--ternary_color);
}

.nav_list_wrapper .active .svg-inline--fa {
    color: var(--Primary_color);
}

.nav_list_wrapper .active .tab span,
.nav_list_wrapper .active .dropdown_tab span {
    color: var(--secondary_color);
}

.tab .caret {
    position: absolute;
    top: 50%;
    left: 92%;
    transform: translate(-50%, -50%);
}

.tab .caret .svg-inline--fa {
    transition: 0.3s ease;
}

.nav_list_wrapper .active .tab::after,
.nav_list_wrapper .active .dropdown_tab::after,
.nav_list_wrapper .tab_active::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1.7%;
    background-color: var(--Primary_color);
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

/*=============css for login and reset password page=========*/
.login_wrapper {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.login_wrapper .card_wrapper {
    width: 90%;
    height: 85%;
    background-color: #fff;
    overflow: hidden;
    border-radius: var(--border_radius);
    box-shadow: var(--box_shadow);
}

.login_wrapper .card_img {
    width: 100%;
    height: 100%;
}

.login_wrapper .card_img img {
    width: 85%;
    height: 90%;
}

.login_wrapper .form_wrapper {
    height: 100% !important;
    display: grid !important;
    place-items: center;
    overflow-y: auto;
}

.form {
    align-items: center;
    display: grid !important;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.login_wrapper .form_wrapper::-webkit-scrollbar {
    width: 0;
}

.login_wrapper .form_wrapper .form {
    width: 70%;
}

.login_wrapper .title h1 {
    color: var(--Primary_color);
    font-weight: bold;
    font-size: 30px;
}

.login_wrapper .title {
    margin-bottom: 2.188rem;
    text-align: center;
}

.login_wrapper .input_wrapper {
    position: relative;
    margin: var(--input_margin) 0 0 0;
}

.login_wrapper .input_wrapper input {
    border: unset;
    border-bottom: 1px solid var(--Primary_color);
    border-radius: unset;
}

.input_wrapper input:focus {
    box-shadow: none;
}

.login_wrapper .input_wrapper input::placeholder {
    color: var(--Primary_color);
}

.login_wrapper .input_wrapper .svg-inline--fa {
    position: absolute;
    top: 50%;
    left: 95%;
    transform: translate(-50%, -50%);
    color: var(--Primary_color);

}

.login_wrapper .reset {
    text-align: right;
    margin-top: 0.625rem;
}

.login_wrapper .reset a {
    color: var(--Primary_color);
}

.login_wrapper .reset span {
    font-weight: bold;
    cursor: pointer;
}

.login_wrapper .button {
    width: 100%;
    display: grid;
    place-items: center;
    background-color: var(--Primary_color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 1.25rem;
    border: unset;
    font-weight: bold;
    margin-top: var(--input_margin);
}

.backto_login_wrapper {
    margin-top: 15px;
}

.backto_login_wrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.backto_login_wrapper .back_icon {
    /* width: 15px;
    height: 15px; */
    padding: 5px;
    border-radius: 50%;
    background-color: var(--Primary_color);
    border: unset;
    color: #fff;
    display: grid;
    place-items: center;
}

.backto_login_wrapper .back_icon .svg-inline--fa {
    font-size: 12px;
}

.backto_login_wrapper h6 {
    color: var(--Primary_color);
    font-size: var(--ternary_font_size);
    margin-left: 10px;
}

/* ========== Css for Loader========== */
.conatiner_loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    /* background-color: #38CAB3; */
}

.laoder_wrapper {
    position: absolute;
    /* top: -2.188rem; */
    transform: scale(1.5);
}

.loader {
    height: 1.563rem;
    width: 0.063rem;
    position: absolute;
    animation: rotate 3.5s linear infinite;
}

.loader .dot {
    position: relative;
    top: 1.875rem;
    width: 0.438rem;
    height: 0.438rem;
    background-color: var(--Primary_color);
    border-radius: 50%;
}

.loader_text {
    position: absolute;
    top: 65%;
    font-size: var(--secondary_font_size);
    font-weight: 600;
    font-family: var(--font_family);
    color: var(--Primary_color);
}

@keyframes rotate {
    30% {
        transform: rotate(220deg)
    }

    40% {
        transform: rotate(450deg);
        opacity: 1;
    }

    75% {
        transform: rotate(720deg);
        opacity: 1;
    }

    76% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: rotate(0deg);
    }

}

.loader:nth-child(1) {
    animation-delay: 0.15s;
}

.loader:nth-child(2) {
    animation-delay: 0.3s;
}

.loader:nth-child(3) {
    animation-delay: 0.45s;
}

.loader:nth-child(4) {
    animation-delay: 0.6s;
}

.loader:nth-child(5) {
    animation-delay: 0.75s;
}

.loader:nth-child(6) {
    animation-delay: 0.9s;
}

/* ======== CSS for profile page =============== */
.profile_wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.profile_wrapper .profile_card {
    width: 90%;
    height: 80%;

    border-radius: var(--border_radius);
    box-shadow: var(--box_shadow);

}

.profile_wrapper .profile_card .profile_header {
    background-color: var(--Primary_color);
    padding: 0.625rem
}

.div_profile1 {
    gap: 1.25rem
}

/* .profile_wrapper .profile_card .profile_body {
    background-color: #fff;
} */

.profile_wrapper .profile_title .profile_icon {
    color: #fff;
    font-size: 0.813rem;
    display: flex;

}

.profile_div {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    color: #fff
}

.profile_wrapper .profile_icon {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    background-color: #fff;
    display: grid;
    place-items: center;


}

.profile_wrapper .profile_icon .svg-inline--fa {
    color: var(--Primary_color);
    font-size: var(--primary_font_size);
}

.profile_body {
    padding: 1.563rem;
}

.profile_wrapper .profile_body .form-control {

    font-size: var(--ternary_font_size) !important;
    font-weight: bold;
    color: var(--secondary_color)
}

.edit_button {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_wrapper .div_profile2 .profile_icon {
    cursor: pointer;
}

/*========css for top header============*/
.top_header {
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: rgba(197, 197, 197, 0.15) 0px 10px 10px -10px;
    padding: 0.125rem 0.875rem;
}

.top_header .title {
    position: relative;
    padding-left: 0.625rem;
    color: var(--secondary_color);
    font-weight: bold;
    font-size: var(--primary_font_size);
}

.top_header .title::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.188rem;
    background-color: var(--Primary_color);
}

.top_header .search_wrapper {
    position: relative;
    box-shadow: rgba(164, 164, 164, 0.1) 0 0 1rem;
    cursor: pointer;
    overflow: hidden;
}

.top_header .search_wrapper input {
    height: 2.313rem;
    border: 0.063rem solid var(--Primary_color);
    transition: 0.1s ease-in-out;
}

.top_header .search_wrapper input:focus {
    box-shadow: none;
}

.top_header .search_wrapper .svg-inline--fa {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--Primary_color);
}

.top_header .search_wrapper input::placeholder {
    margin-left: 20px;
}

/*===========css for pagination=============*/
.paginationWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.paginationInputWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 32%;
    height: 30px;
    border: 1px solid var(--Primary_color);
    border-radius: 3px;
}

.paginationInputWrapper .form-control {
    border: unset;
    height: 100%;

}


.paginationWrapper .btn_pagination {
    color: var(--Primary_color);
    padding: 2px 8px;
    border-radius: 3px;
    border: 1px solid var(--Primary_color);
    background-color: #fff;
}

.paginationWrapper .btn_pagination:disabled {
    background-color: rgb(235, 235, 235);
    cursor: not-allowed
}

.paginationInputWrapper .find_btn {
    border: unset;
    background-color: var(--Primary_color);
    padding: 0px 10px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    color: #fff;

}

.paginationWrapper .paginationInputWrapper h6 {
    color: var(--Primary_color);
}


.paginationInputWrapper .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.paginationInputWrapper .find_btn:hover {
    background-color: var(--Primary_color);
    color: #fff
}

/* old pagination */
.pagination_wrapper .pagination li {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--ternary_color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    box-shadow: var(--box_shadow);
}

.pagination_wrapper .pagination li a {
    font-size: 10px;
}

.pagination_wrapper .pagination .active {
    color: #fff;
    background-color: var(--Primary_color);
}

.pagination_wrapper .pagination .previous,
.pagination_wrapper .pagination .next {
    width: unset !important;
    border-radius: 0 !important;
    background-color: unset !important;
    box-shadow: none;
}

.pagination_wrapper .pagination .previous a,
.pagination_wrapper .pagination .next a {
    color: var(--Primary_color) !important;
    cursor: pointer;
    font-weight: bold;
}

/*============css for table============*/
.routes_view_section section {
    height: 93%;
    background-color: #F7F9FA;
    padding: 0.875rem;
    position: relative;
}

.routes_view_section .section_wrapper {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: rgba(164, 164, 164, 0.1) 0 0 1rem;
    border-radius: var(--border_radius);
    padding: 1% 1% 0 1%;
}

.section_header_wrapper {
    width: 100%;
    height: 8%;
    /* background-color: #848498; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.survey_management_header {
    height: 13%;
    align-items: center;
}

.button_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button,
.filter_button {
    border: 1px solid var(--Primary_color);
    font-size: var(--ternary_font_size);
    padding: 2px 15px;
    color: var(--Primary_color);
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.3s ease-in;
}

.button:hover {
    background-color: var(--Primary_color);
    color: #fff;
}

.filter_button {
    color: #fff;
    background-color: var(--Primary_color);
}

.filter_button:hover {
    background-color: #fff;
    color: var(--Primary_color);
}

.table_wrapper {
    width: 100%;
    height: 92%;
    overflow: auto;
    position: relative;
}

.table_wrapper .table {
    margin-bottom: 0;
}

.election_table,
.survey_data_wrapper .table {
    min-width: 1100px;
}

.organization_table {
    min-width: 1600px;
}

.voter_table {
    min-width: 1700px;
}

.survey_data_wrapper {
    height: 87%;
}

.survey_trends_wrapper .table,
.voter_list_wrapper .table {
    min-width: 1300px;
}

.onField_input_table_wrapper {
    height: 77%;
}

.onField_input_table_wrapper .table,
.activity_table_wrapper .table {
    min-width: 1350px;
}

.table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F7F9FA;
    border: 1px solid #EDEDF5;
    border-left: none;
    border-right: none;
    position: sticky;
    top: 0;
    z-index: 1;
}

.table_header .row,
.table_data .row {
    width: 100%;
}

.table_header h5 {
    font-size: var(--ternary_font_size);
    font-weight: 600;
    color: var(--ternary_color);
    text-align: center;
}

.table_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDEDF5;
}

.table_data h6 {
    font-size: var(--ternary_font_size);
    color: var(--ternary_color);
    text-align: center;
    text-transform: capitalize;
}

.survey_trends_wrapper .table_data h6 {
    color: #fff;
}

.table_data .voterId h6 {
    text-transform: unset;
}

.serial_number,
.gender {
    width: 8%;
}

.election_name,
.booth_name,
.full_name,
.event_name,
.description {
    width: 20%;
}

.name,
.election_type,
.password,
.designation,
.alloted_member {
    width: 15%;
}

.party,
.city,
.voterId {
    width: 15%;
}

.election_year,
.polling_name,
.page_number,
.mobile_number,
.booth_name {
    width: 13%;
}

.date,
.userId,
.survey_name,
.points {
    width: 13%;
}

.action,
.gender,
.election_action {
    min-width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.election_action {
    width: 15%;
}

.row_action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.action .action_button,
.election_action .action_button {
    font-size: 12px;
    padding: 1px 8px;
    border-radius: 15px;
    background-color: var(--Primary_color);
    border: 1px solid var(--Primary_color);
    color: #fff;
}

.action .svg-inline--fa,
.row_action .svg-inline--fa,
.election_action .svg-inline--fa {
    color: var(--Primary_color);
    cursor: pointer;
}

.password {
    position: relative;
}

.password .svg-inline--fa {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
    padding: 0;
    color: var(--ternary_color);
    font-size: 14px;
}

.table_data .password h5 {
    font-size: 24px;
    margin-top: -15px;
    color: var(--ternary_color);
}

.password h6 {
    text-align: left;

}


.vote {
    width: 25%;
    text-align: center;
    overflow: hidden;
}

/*==========css for modal===========*/
.modal_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #bababa4a;
    display: grid;
    place-items: center;
    z-index: 1;
}

.form_wrapper_main {
    width: 100%;
    height: 100%;
    background-color: #F7F9FA;
    display: grid;
    place-items: center;
}

.modal_wrapper .card_wrapper,
.form_wrapper_main .card_wrapper {
    width: 60%;
    max-height: 95%;
    overflow-y: auto;
    background-color: #fff;
    border-radius: var(--border_radius);
    border: var(--border);
    box-shadow: var(--box_shadow);
}

.form_wrapper_main .card_wrapper {
    width: 90%;
}

.modal_wrapper .card_header,
.form_wrapper_main .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-bottom: var(--border);
    position: sticky;
    top: 0;
    background-color: #fff;
}

.modal_wrapper .card_header .title,
.form_wrapper_main .card_header .title {
    position: relative;
    padding-left: 10px;
}

.modal_wrapper .card_header .title h5,
.form_wrapper_main .card_header .title h5 {
    font-size: var(--secondary_font_size);
    color: var(--secondary_color);
    font-weight: 600;
}

.modal_wrapper .card_header .title::after,
.form_wrapper_main .card_header .title::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: var(--Primary_color);
}

.modal_wrapper .card_header .close_button,
.form_wrapper_main .card_header .close_button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--Primary_color);
    display: grid;
    place-items: center;
    color: var(--Primary_color);
    cursor: pointer;
}

.modal_wrapper .card_body,
.form_wrapper_main .card_body {
    padding: 15px;
}

.input_box_wrapper {
    margin-bottom: 10px;
}

.input_box_wrapper label {
    font-size: var(--ternary_font_size);
    color: var(--secondary_color);
    font-weight: 600;
    margin-bottom: 5px;
}

.input_box_wrapper input,
.input_box_wrapper select,
.input_box_wrapper textarea,
.react-datepicker-wrapper input {
    border: var(--border);
    box-shadow: var(--box_shadow);
    line-height: 1.4;
    font-size: var(--ternary_font_size);
    color: var(--ternary_color) !important;
}

.multiSelectContainer {
    border: unset;
}

.multiSelectContainer .searchWrapper {
    position: relative;
    min-height: 32.93px;
    padding: 0;
    overflow: hidden;
    box-shadow: var(--box_shadow);
}

.multiSelectContainer .searchWrapper input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--border_radius);
    margin-top: 0;
}

.multiSelectContainer .searchWrapper .svg-inline--fa {
    font-size: 14px;
}

.multiSelectContainer .searchWrapper input:focus {
    border: unset;
}

.icon_down_dir {
    right: 12px;
}

.icon_down_dir:before {
    display: none;
}

.chip img {
    display: none;
}

.input_box_wrapper input::placeholder,
.input_box_wrapper textarea::placeholder,
.react-datepicker-wrapper input::placeholder {
    color: var(--ternary_color);
}

.input_box_wrapper input:focus,
.input_box_wrapper select:focus,
.input_box_wrapper textarea:focus,
.react-datepicker-wrapper input:focus {
    box-shadow: none;
    border: var(--border);
}

.react-datepicker-wrapper input {
    width: 100%;
    border-radius: var(--border_radius);
    padding: 0.375rem 0.75rem;
}

.react-datepicker-ignore-onclickoutside:focus {
    outline: none;
}

.input_box_wrapper .party_select_box,
.input_box_wrapper .designation_select_box {
    text-transform: capitalize;
}

.modal_wrapper .submit_button_wrapper,
.form_wrapper_main .submit_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_wrapper .submit_button_wrapper .filter_button,
.form_wrapper_main .submit_button_wrapper .filter_button {
    padding: 3px 25px;
    font-weight: 600;
    margin-top: 10px;
}

.form-select_data {
    width: 0;
    float: right;
}

.button_wrapper select {
    line-height: 1.4;
    font-size: var(--ternary_font_size);
    border: 1px solid var(--Primary_color);
    color: var(--Primary_color);
    border-radius: 20px;
    padding: 3px 30px 3px 10px;
}

.button_wrapper select:focus {
    box-shadow: none;
}

.import_data_wrapper {
    width: 60%;
    margin: auto;
}

.import_data_wrapper label {
    width: 100%;
    border: 1px solid var(--Primary_color);
    padding: 3% 2%;
    box-shadow: var(--box_shadow);
    border-radius: var(--border_radius);
    text-align: center;
    color: var(--Primary_color);
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;
}

.import_data_wrapper .svg-inline--fa {
    font-size: 24px;
    margin-bottom: 15px;
}

.import_data_wrapper span {
    color: var(--ternary_color);
}

/* ========== Designation CSS ============== */


.designation_wrapper {
    width: 100%;
    height: 100%;
    background-color: #F7F9FA;
    display: grid;
    place-items: center;
}


.designation_wrapper .designation_card {
    width: 90%;
    height: 90%;
    overflow-y: auto;
    background-color: #fff;
    border-radius: var(--border_radius);
    border: var(--border);
    box-shadow: var(--box_shadow);

}

.designation_wrapper .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-bottom: var(--border);
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.designation_wrapper .card_header .title {
    position: relative;
    padding-left: 10px;
}

.designation_wrapper .card_header .title h5 {
    font-size: var(--ternary_font_size);
    color: var(--secondary_color);
    font-weight: 600;
}

.designation_wrapper .designation_card .card_header .close_button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--Primary_color);
    display: grid;
    place-items: center;
    color: var(--Primary_color);
    cursor: pointer;
}

.designation_wrapper .card_body {
    padding: 1.563rem;
}

.designation_wrapper .designation_tab_heading h6 {
    font-size: var(--secondary_font_size);
    color: var(--Primary_color);
    font-weight: bold;

}

.designation_wrapper .check_box_wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.check_box_wrapper label {
    font-size: var(--ternary_font_size);
    color: var(--secondary_color);
    font-weight: 600;
    margin-bottom: 5px;
}

.designation_card .card_header .close_button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--Primary_color);
    display: grid;
    place-items: center;
    color: var(--Primary_color);
    cursor: pointer;
}

.action_designation .svg-inline--fa {
    color: var(--Primary_color);
    cursor: pointer;
}

.booth_access_wrapper {
    position: relative;
}

.booth_access_wrapper .add_access_button {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-50%, -20%);
    border: var(--border);
    box-shadow: var(--box_shadow);
    color: var(--ternary_color) !important;
    padding: 3px 10px;
    border-radius: var(--border_radius);
    background-color: var(--Primary_color);
    color: #fff !important;
}

.booth_access_wrapper .trash_button {
    position: absolute;
    top: 40%;
    right: 15%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #e23a3a;
}

.gender_input_wrapper {
    display: flex;
    gap: 15px;
}

/*=====survey management css========*/
.vote .party_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}

.gender_input input {
    color: var(--Primary_color);
    margin-top: 0;
}

.gender_input label {
    font-size: var(--ternary_font_size);
    color: var(--ternary_color);
    margin-left: 8px;
    font-weight: normal;
    margin-bottom: 0;
}

.gender_input {
    display: flex;
    align-items: center;
}

.vote .party_wrapper .party_input {
    width: calc(100% / 3);
}

.vote .party_wrapper input {
    margin-top: 0.35rem;
    border: 1px solid var(--Primary_color);
}

.vote .party_wrapper .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='lightgreen'/%3e%3c/svg%3e");
    background-color: unset !important;
    box-shadow: unset;
}

.vote .party_wrapper label {
    margin-left: 5px;
    color: var(--ternary_color);
    font-size: var(--ternary_font_size);
}

.survey_data_wrapper .link a {
    border-bottom: 1px solid var(--ternary_color);
    cursor: pointer;
}

.survey_card_wrapper,
.data_analysis_card_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.survey_card_wrapper .survey_card,
.data_analysis_card_wrapper .survey_card {
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    border-radius: var(--border_radius);
    background-image: linear-gradient(to right, #40DA9C, #3CC0AD);
}

.data_analysis_card_wrapper {
    margin: 15px 0;
    gap: 20px;
}

.data_analysis_card_wrapper .survey_card {
    min-width: 25%;
}

.survey_card_wrapper .survey_card:nth-child(2),
.data_analysis_card_wrapper .survey_card:nth-child(2) {
    background-image: linear-gradient(to right, #FFC944, #FF9F50);
}

.survey_card_wrapper .survey_card h6,
.data_analysis_card_wrapper .survey_card h6 {
    font-size: 12px;
    color: #fff;
    text-shadow: var(--text_shadow);
}

.data_analysis_card_wrapper .survey_card h6 {
    font-size: var(--primary_font_size);
    font-weight: 600;
}

.survey_card .icon_wrapper {
    text-align: center;
}

.survey_card .icon_wrapper .svg-inline--fa {
    color: var(--Primary_color);
    color: #fff;
    text-shadow: var(--text_shadow);
}

.data_analysis_card_wrapper .survey_card .icon_wrapper .svg-inline--fa {
    font-size: 24px;
    margin-bottom: 7px;
}

.input_box_wrapper .checkbox_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.checkbox_wrapper .form-check-input {
    margin-top: 0;
    border: 1px solid var(--Primary_color);
}

.checkbox_wrapper h6 {
    font-size: var(--ternary_font_size);
    color: var(--ternary_color);
}

.aid_card_wrapper {
    width: 80% !important;
}

/*==========css for Data Analysis===========*/
.survey_analysis_tab_wrapper {
    display: flex;
    align-items: center;
}

.survey_analysis_tab_wrapper .tab {
    min-width: 10%;
    padding: 5px 10px;
    border: 1px solid var(--Primary_color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey_analysis_tab_wrapper .tab h5 {
    font-size: var(--primary_font_size);
    font-weight: 600;
    color: var(--Primary_color);
}

.survey_analysis_tab_wrapper .active {
    background-color: var(--Primary_color);
}

.survey_analysis_tab_wrapper .active h5 {
    color: #fff;
}

.survey_analysis_graph_wrapper {
    width: 100%;
    height: 60vh;
    overflow-x: auto;
}

.survey_analysis_graph_wrapper .graph_main {
    width: 100%;
    overflow-x: auto;
}

.survey_analysis_graph_wrapper::-webkit-scrollbar {
    width: 0 !important;
}

.survey_analysis_graph_wrapper canvas {
    max-height: 350px;
}

/*==========css for Voting Day Tab===============*/
.counter_card_wrapper {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 0 15px 0;
}

.counter_card_wrapper .counter_card {
    width: calc(100% / 6);
    text-align: center;
    padding: 5px;
    border-radius: var(--border_radius);
    background-image: linear-gradient(to right, #21D1D6, #4994E2);
    color: #fff;
}

.counter_card_wrapper .counter_card h5 {
    font-size: var(--secondary_font_size);
    margin-bottom: 5px;
    font-weight: 600;
    text-shadow: var(--text_shadow);
}

.counter_card_wrapper .counter_card h6 {
    font-size: var(--ternary_font_size);
    font-weight: 600;
    text-shadow: var(--text_shadow);
}

.counter_card_wrapper .counter_card:nth-child(2) {
    background-image: linear-gradient(to right, #3FD899, #3CBEAE);
}

.counter_card_wrapper .counter_card:nth-child(3) {
    background-image: linear-gradient(to right, #C087F7, #9342D0);
}

.counter_card_wrapper .counter_card:nth-child(4) {
    background-image: linear-gradient(to right, #F06A7C, #F95E91);
}

.counter_card_wrapper .counter_card:nth-child(5) {
    background-image: linear-gradient(to right, #FFCB43, #FF9852);
}

.msi_panel_wrapper {
    height: 100%;
}

.msi_panel_wrapper .col-lg-6 {
    height: 50%;
}

.msi_panel_wrapper .col-lg-6:nth-child(2) .msi_panel_card_wrapper {
    overflow-y: auto;
}

.msi_panel_wrapper .col-lg-6:nth-child(2) .msi_panel_card_wrapper::-webkit-scrollbar {
    height: 0;
}

.msi_panel_card_wrapper,
.dashboard_graph_card_wrapper {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: var(--border_radius);
    padding: 8px;
}

.msi_panel_card_wrapper .booth_card_wrapper {
    width: 100%;
    display: flex;
    gap: 15px;
}

.booth_card_wrapper .booth_card {
    width: calc(100% / 2);
}

.booth_card_wrapper .booth_card .title h5 {
    font-size: var(--ternary_font_size);
    color: #FFC446;
    font-weight: 600;
    text-shadow: 3px 0px 6px #d3d2d2;
    text-align: left;
}

.booth_card_wrapper .booth_table {
    text-align: center;
    margin-top: 10px;
}

.booth_table .header,
.booth_table .data {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 3px;
}

.booth_table .data {
    border-bottom: 1.2px solid rgb(234, 233, 233);
}

.booth_table .header h6 {
    color: #fff !important;
    text-shadow: var(--text_shadow);
}

.booth_card_wrapper .booth_table h6 {
    font-size: 13px;
    color: var(--ternary_color);
}

.booth_table .data .column a {
    border-bottom: 1px solid grey;
    cursor: pointer;
}

.booth_table .column {
    width: calc(100% / 3);
}

.booth_card_wrapper .booth_card:nth-child(1) .header {
    background-image: linear-gradient(to right, #FFC944, #FFA150);
}

.booth_card_wrapper .booth_card:nth-child(2) .header {
    background-image: linear-gradient(to right, #40DA9C, #3CC3AA);
}

.booth_card_wrapper .booth_card:nth-child(2) .title h5 {
    color: var(--Primary_color);
}

/* =============BenificialVote ==============*/
.beneficial_voters_count {
    min-width: 230px;
    border: 1px solid var(--Primary_color);
    padding: 3px 15px;
    color: var(--Primary_color);
    background-color: #fff;
    border-radius: 15px;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: var(--ternary_font_size);
}

.beneficial_voters_count h6 {
    font-size: var(--ternary_font_size);
}

.msi_panel_card_wrapper .title h5,
.dashboard_graph_card_wrapper .title h5 {
    text-align: center;
    font-size: var(--secondary_font_size);
    font-weight: 600;
    color: var(--secondary_color);
}

.booth_report_graph_wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
}

.booth_report_graph_wrapper .booth_report_graph {
    width: auto;
    overflow-x: auto;
}

.booth_report_graph_wrapper::-webkit-scrollbar {
    width: 0 !important;
}

.booth_report_graph_wrapper canvas {
    max-height: 210px;
}

.final_report_graph_wrapper {
    width: 100%;
    height: 100%;
}

.final_report_graph {
    height: 100%;
    overflow-y: auto;
    display: grid;
    place-items: center;
}

.final_report_graph canvas {
    width: 180px !important;
    height: 180px !important;
}

.final_report_label_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}

.final_report_label_wrapper .label_wrapper,
.voting_area_label_wrapper .voting_area_label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.voting_area_label_wrapper .voting_area_label {
    margin-bottom: 10px;
}

.final_report_label_wrapper .label_wrapper h6,
.voting_area_label_wrapper .voting_area_label h6 {
    color: var(--ternary_color);
    font-size: 12px;
}

.final_report_label_wrapper .label_wrapper .label_color_box,
.voting_area_label_wrapper .label_color_box {
    width: 40px;
    height: 12px;
}

.final_report_label_wrapper .label_wrapper:nth-child(1) .label_color_box {
    background-color: #3BCD90;
}

.final_report_label_wrapper .label_wrapper:nth-child(2) .label_color_box {
    background-color: #25D2DC;
}

.final_report_label_wrapper .label_wrapper:nth-child(3) .label_color_box {
    background-color: #FA6D83;
}

.hyperlink a {
    border-bottom: 1px solid var(--ternary_color);
    cursor: pointer;
    margin-bottom: 8px;
}

/*======css for voting area graph=====*/
.voting_area_label_wrapper .voting_area_label:nth-child(1) .label_color_box {
    background-color: var(--Primary_color);
}

.voting_area_label_wrapper .voting_area_label:nth-child(2) .label_color_box {
    background-color: #6DE8D4;
}

.voting_area_label_wrapper .voting_area_label:nth-child(3) .label_color_box {
    background-color: #FF7D7D;
}

.voting_area_graph_wrapper {
    width: 100%;
    height: 100%;
    /* overflow-y: auto; */
}

.voting_area_graph_wrapper::-webkit-scrollbar {
    width: 0;
}

/*=================================*/
.voting_area_graph_wrapper {
    position: relative;
    height: 100%;
}

.voting_area_graph_wrapper .svg_wrapper {
    position: absolute;
    width: 100px;
    height: 80px;
    overflow: hidden;
}

.voting_area_graph_wrapper .svg_wrapper svg {
    width: 100%;
    height: 100%;
}

.voting_area_graph_wrapper .svg_wrapper svg path {
    stroke: grey;
    stroke-width: 20;
}

.voting_area_graph_wrapper .svg_wrapper h6 {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(1) {
    top: 30%;
    left: 7%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(2) {
    top: -10%;
    left: 30%;
    width: 130px;
    height: 110px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(3) {
    top: 66.3%;
    right: 8.5%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(4) {
    top: 70%;
    left: -19%;
    width: 150px;
    height: 130px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(5) {
    top: 97%;
    left: 15%;
    width: 80px;
    height: 75px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(5) h6 {
    font-size: 11px;
    transform: translate(-50%, -50%) rotate(45deg);
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(6) {
    top: 86%;
    left: 30%;
    width: 80px;
    height: 50px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(7) {
    top: 143.5%;
    left: -11.4%;
    width: 129px;
    height: 130px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(8) {
    top: 180%;
    left: 7%;
    width: 190px;
    height: 90px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(8) h6 {
    top: 40%;
    transform: translate(-20%, -50%) rotate(45deg);
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(9) {
    top: 113%;
    left: 40%;
    width: 104px;
    height: 100px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(10) {
    top: 95%;
    right: 0%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(10) h6 {
    top: 70%;
    left: 55%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(11) {
    top: 120%;
    left: -20%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(12) {
    top: 180%;
    right: 23%;
    width: 107px;
    height: 90px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(12) h6 {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(13) {
    top: 182%;
    right: 5%;
    width: 80px;
    height: 60px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(14) {
    top: 285%;
    left: -7%;
    width: 120px;
    height: 95px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(14) h6 {
    top: 35%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(15) {
    top: 264.5%;
    left: 25.9%;
    width: 115px;
    height: 88px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(15) h6 {
    top: 40%;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(16) {
    top: 225%;
    right: 13.5%;
    width: 90px;
    height: 78px;
}

.voting_area_graph_wrapper .svg_wrapper:nth-child(16) h6 {
    left: 55%;
}

/*==================css for Activity management tab===================*/
.event_button_wrapper {
    height: 100%;
    position: relative;
}

.event_button_wrapper button {
    position: absolute;
    bottom: 13px;
    border: var(--border);
    padding: 2px 8px;
    background-color: #fff;
    border-radius: var(--border_radius);
}

.event_button_wrapper button .trash {
    color: #e23a3a;
}

/*=================css for Download Template============*/
.download_template_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.download_template_wrapper .card_wrapper {
    position: relative;
    min-width: 300px;
    text-align: center;
    border-radius: var(--border_radius);
    background-color: var(--Primary_color);
    padding: 1% 2%;
    overflow: hidden;
    cursor: pointer;
}

.download_template_wrapper .card_wrapper::before {
    position: absolute;
    content: "";
    left: -10%;
    bottom: -6%;
    width: 30%;
    height: 40%;
    background-color: #fff;
    transform: rotate(45deg);
}

.download_template_wrapper .card_wrapper h6 {
    color: #fff;
    text-shadow: var(--text_shadow);
    font-size: var(--ternary_font_size);
    font-weight: 600;
}

.download_template_wrapper .card_wrapper .svg-inline--fa {
    color: #fff;
    box-shadow: var(--box_shadow);
}

.download_template_wrapper .card_wrapper:nth-child(1) h6,
.download_template_wrapper .card_wrapper:nth-child(2) h6 {
    margin: 5px 0;
}

.download_template_wrapper .card_wrapper:nth-child(1) {
    background-image: linear-gradient(to right, #40DA9C, #3CC0AD);
}

.download_template_wrapper .card_wrapper:nth-child(2) {
    background-image: linear-gradient(to right, #FFC944, #FF9F50);
}

.download_template_wrapper .card_wrapper .download_icon {
    position: absolute;
    left: 3%;
    bottom: 7%;
    box-shadow: unset;
}

.download_template_wrapper .card_wrapper:nth-child(1) .download_icon {
    color: var(--Primary_color);
}

.download_template_wrapper .card_wrapper:nth-child(2) .download_icon {
    color: #FFC944;
}

/*==================css for Dashboard=================*/
.current_progress_wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.dashboard_graph_wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
}

.dashboard_graph_wrapper canvas {
    max-height: 500px !important;
}

.immediate_focus_graph canvas {
    cursor: pointer;
}

.dashboard_card_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5.5%;
}

.dashboard_card_wrapper .card_wrapper {
    position: relative;
    box-shadow: var(--box_shadow);
    width: calc(100% / 5);
    height: 150px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.dashboard_card_wrapper .card_wrapper .inner_card {
    margin: auto;
    width: 95%;
    height: 95%;
    display: grid;
    place-items: center;
    text-align: center;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 40px -12px inset, rgba(179, 179, 179, 0.3) 0px 18px 36px -18px inset;
    padding: 2%;
}

.dashboard_card_wrapper .card_wrapper .count {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 70%;
    height: 20%;
    background-color: #fff;
    display: grid;
    place-items: center;
}

.dashboard_card_wrapper .card_wrapper .count h6 {
    color: var(--ternary_color);
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
}

.dashboard_card_wrapper .card_wrapper .inner_card .svg-inline--fa {
    font-size: 22px;
    color: #fff;
    box-shadow: var(--box_shadow);
    margin-bottom: 8px;
}

.dashboard_card_wrapper .card_wrapper .inner_card h6 {
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    text-shadow: var(--text_shadow);
}

.dashboard_card_wrapper .card_wrapper:nth-child(1) .inner_card {
    background-image: linear-gradient(to bottom, #20D9DB, #5487E7);
}

.dashboard_card_wrapper .card_wrapper:nth-child(2) .inner_card {
    background-image: linear-gradient(to bottom, #F85A94, #FA7081);
}

.dashboard_card_wrapper .card_wrapper:nth-child(3) .inner_card {
    background-image: linear-gradient(to bottom, #40DC9A, #3CB9B2);
}

.dashboard_card_wrapper .card_wrapper:nth-child(4) .inner_card {
    background-image: linear-gradient(to bottom, #FFD042, #FF8A56);
}

.dashboard_card_wrapper .card_wrapper:nth-child(5) .inner_card {
    background-image: linear-gradient(to bottom, #C48DFA, #8831C6);
}

.dashboard_card_wrapper .card_wrapper:nth-child(1) .count {
    border-bottom: 1.5px solid #29CADD;
}

.dashboard_card_wrapper .card_wrapper:nth-child(2) .count {
    border-bottom: 1.5px solid #FA6A86;
}

.dashboard_card_wrapper .card_wrapper:nth-child(3) .count {
    border-bottom: 1.5px solid #3FD69E;
}

.dashboard_card_wrapper .card_wrapper:nth-child(4) .count {
    border-bottom: 1.5px solid #FFC545;
}

.dashboard_card_wrapper .card_wrapper:nth-child(5) .count {
    border-bottom: 1.5px solid #B475EC;
}


.current_progress_wrapper .dashboard_graph_card_wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: var(--box_shadow);
    border-radius: var(--border_radius);
}

.current_progress_wrapper .main_card_column {
    height: 70%;
}